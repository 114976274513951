import React, { useState, useRef, useEffect } from "react";
import './UserView.scss';
import SidePanel from '../../components/molecules/side-panel/SidePanel';
import ee from '@google/earthengine';
import LoadingIconTwo from '../../components/atoms/LoadingIconTwo';
import {GEE_URL} from '../../env'
import moment from 'moment';


export default function UserView() {

  const [isLoading, setIsLoading] = useState({ startView: false, startViewBtn: false, ndwiBtn: false, eviBtn: false, dateFilter: false });
  const [holeName, setHoleName] = useState("Loch Auswahl");
  const [buttonActive, setButtonActive] = useState({ startView: true, ndwi: false, evi: false })
  const [mapLayers, setMapLayers] = useState([]);
  const [map, setMap] = useState (null);
  const [initialOptions, setInitialOptions] = useState({});
  const [chartData, setChartData] = useState([]);
  const [chartDates, setChartDates] = useState([]);
 

  let ref = useRef();

  let mapOptions = {
    center: { lng: 10.415039, lat: 51.151786},
    zoom: 6,
    mapTypeId: window.google.maps.MapTypeId.SATELLITE
  };

  const setMapOptions = async (options) => {
    mapOptions = options;
  }
 
  // Display an ee image on the google map.
  const showEEImage = async (tempMapLayers, button) => {
    let tileSources = [[], []];
    console.log(button);
    await tempMapLayers.map((layer) => {
      switch (button) {
        case 'ndwi':
          renderLayer(tileSources, layer, 'NDWI');
          displayGeometry(tileSources, layer);
          break;
        case 'evi':
          renderLayer(tileSources, layer, 'EVI');
          displayGeometry(tileSources, layer);
          break;
        case 'view':
          renderLayer(tileSources, layer, 'Rohbild');
          displayGeometry(tileSources, layer);
          break;
        case 'Loch Auswahl':
          displayActiveLayer(tileSources, layer);
          renderLayer(tileSources, layer, 'geometry');
          break;
        case 'Alle Löcher':
          displayActiveLayer(tileSources, layer);
          renderLayer(tileSources, layer, 'geometry');
          break;
        case 'Loch 1':
          displayActiveLayer(tileSources, layer);
          renderLayer(tileSources, layer, 'geometry1', true);
          break;
        case 'Loch 2':
          displayActiveLayer(tileSources, layer);
          renderLayer(tileSources, layer, 'geometry2', true);
          break;
        case 'Loch 3':
          displayActiveLayer(tileSources, layer);
          renderLayer(tileSources, layer, 'geometry3', true);
          break;
        case 'Loch 4':
          displayActiveLayer(tileSources, layer);
          renderLayer(tileSources, layer, 'geometry4', true);
          break;
        default:
          console.log ("no match");
          break;
      };    
    });

    console.log(mapOptions);
    map.setOptions(mapOptions);
    map.overlayMapTypes.clear();
    for (let tileSource of tileSources[0]) {
      map.overlayMapTypes.insertAt(0, new ee.layers.ImageOverlay(tileSource));
    }
    for (let tileSource of tileSources[1]) {
      map.overlayMapTypes.insertAt(1, new ee.layers.ImageOverlay(tileSource));
    }
  
    setIsLoading({startView:false, startViewBtn:false, ndwiBtn:false, eviBtn:false, dateFilter:false});

  };
 
  /**
   * This function iterates over every layer and adds the layers accordingly
   * 
   * @author Phillip Sada & Hagen Hoppenstedt
   * 
   * @param {*} tileSources An array to collect all single tiles
   * @param {*} layer The current layer we are iterating
   * @param {*} name The name of the layer we want to draw
   * @param {*} geometry Is set to true, if we want to focus on a single hole
   * @param {*} firstLayer Is set to true, if we are inspecting the lowest layer
   * @returns 
   */
  const renderLayer = async(tileSources, layer, name, geometry = false) => {
    console.log("Starting to render...");
    if (layer.name.includes(name)) {
      console.log("Rendering layer: " + layer.name)
      const mapid = layer.mapid;
      const tileSource = new ee.layers.EarthEngineTileSource({
        mapid
      })

      /**
       * Check if the current layer contains geometry, if so differentiate between the whole geometry
       * We only want to insert the single geometric elements if we zoom into something
      */
      if (layer.name.includes("geometry")) {

        if (/\d/.test(layer.name)) {

          /**
           * If we want to inspect a specific hole set the coordinates, if not abort the function. 
           * Since all the geometry is drawn with the geometry object we don't want to draw the
           * single elements
           */
          if (geometry) {
            await setMapOptions({
              center: {
                lng: layer.center.lng,
                lat: layer.center.lat
              },
              zoom: 17,
              mapTypeId: window.google.maps.MapTypeId.SATELLITE
            });
            console.log("Zooming into a hole: " + mapOptions);
            return tileSources[1].push(tileSource);
          }
          else {
            return;
          }
        }
        else if (!geometry) {
          await setMapOptions(initialOptions);
          console.log("Viewing all holes: " + mapOptions);
          return tileSources[1].push(tileSource);
        }
      }
      return tileSources[0].push(tileSource);
    }
  }
  /**
   * Helps to ensure that the active layer (Start View, NDWI or EVI) is shown 
   * so that when a hole is selected it would be displayed on the active layer
   * 
   * @author Phillip Sada
   * 
   * @param {*} tileSources 
   * @param {*} layer 
   */
 const displayActiveLayer = async(tileSources, layer)=>{
    switch(true){
      case buttonActive.startView:
        renderLayer(tileSources, layer, 'Rohbild');
        break;
      case buttonActive.ndwi:
        renderLayer(tileSources, layer, 'NDWI');
        break;
      case buttonActive.evi:
        renderLayer(tileSources, layer, 'EVI');
        break;
      default:
        break;
    }
 }

 /**
  * Helps to ensure that the right hole is displayed on the layer
  * 
  * @author Phillip Sada
  * 
  * @param {*} tileSources 
  * @param {*} layer 
  */
 const displayGeometry = async(tileSources, layer) =>{
    switch(holeName){
      case 'Loch Auswahl':
        renderLayer(tileSources, layer, 'geometry');
        break;
      case 'Alle Löcher':
        renderLayer(tileSources, layer, 'geometry');
        break;
      case 'Loch 1':
        renderLayer(tileSources, layer, 'geometry1', true);
        break;
      case 'Loch 2':
        renderLayer(tileSources, layer, 'geometry2', true);
        break;
      case 'Loch 3':
        renderLayer(tileSources, layer, 'geometry3', true);
        break;
      case 'Loch 4':
        renderLayer(tileSources, layer, 'geometry4', true);
        break;
      default:
        break;
    }
  }  
 
  /**
    * Gets data coming from the node express server, formats it and saves it in the react state ,
    * 
    * @author Philip Sada
    */
  
  const initialize = () => {
    setIsLoading({ startView: true, startViewBtn: false, ndwiBtn: false, eviBtn: false, dateFilter: false });

    fetch(GEE_URL+"/gee-scripts")
    .then((response) => response.text())
    .then((data) => {
      const formattedData = JSON.parse(data);
      const mapParams = {
          center: { lng: formattedData.mapData.mapParams.lng, lat: formattedData.mapData.mapParams.lat },
          zoom: formattedData.mapData.mapParams.zoom,
          mapTypeId: window.google.maps.MapTypeId.SATELLITE
      }
      setInitialOptions(mapParams);
      setMapOptions(mapParams);
      setMapLayers(formattedData.mapData.mapLayers);
      setIsLoading({startView:false, startViewBtn:false, ndwiBtn:false, eviBtn:false, dateFilter:false});

      /** These date values will be used in the xAxis of the chart */
      setChartDates(formattedData.chartData.xAxis);
        
      /**  These data will be used in the yAxis of the chart */
       setChartData(formattedData.chartData.yAxis)

    })
    .then(() => setMap(new window.google.maps.Map(ref.current, mapOptions)));

  }

  useEffect(() => {
    console.log("Initialize...");
    initialize();
  }, []);

  return (
    <>
      {
        isLoading.startView ?
          <div className='loading-screen'>
            <div className='card loading-card'>
              <LoadingIconTwo />
              <div className='mt-4'>
                <p> Please wait...</p>
              </div>
            </div>
          </div> : <div></div>
      }



      <SidePanel
        showEEImage={showEEImage}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        holeName={holeName}
        setHoleName={setHoleName}
        buttonActive={buttonActive}
        setButtonActive={setButtonActive}
        mapLayers={mapLayers}
        setMapLayers={setMapLayers}
        mapOptions={mapOptions}
        setMapOptions={setMapOptions}
        chartData={chartData}
        chartDates={chartDates}


      />

      <div ref={ref} style={{ height: '100vh', width: '100%' }} />



    </>
  )

}

