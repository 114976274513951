import React, { useState } from "react";
import { Link } from "react-router-dom";
import './UserProfil.scss';
import NavigationBar from '../../../../components/navigation-bar/NavigationBar';
import golfImg from '../../../../assets/images/golf.jpg';
import golfBallImg from '../../../../assets/images/golf-ball.jpg';
import loginImg from '../../../../assets/images/login.jpg';
import codeImg from '../../../../assets/images/code.jpg';
import EditProfil from '../edit-profil/EditProfil';
import DeleteProfil from '../../../../utils/Delete';
import ChangeGolfCourse from "../change-golfcourse/ChangeGolfCourse";
import ChangeMembership from "../change-membership/ChangeMembership";
import ChangePassword from "../change-password/ChangePassword";
import { Component } from "react";
import { getApi } from "../../../../services/api/requestApi";
import LoadingIcon from "../../../../components/atoms/LoadingIcon";

export default class UserProfil extends Component{

    constructor (props) {
        super (props);
        this.state = {
            username: "",
            firstName: "",
            lastName: "",
            email: "",
            address: "",
            phone: "",
            zip: "",
            city: "",
            membership: "",
            roles: [],
            currentAoi: "",
            currentElem: null,
            availableAois: null,
            showEditProfilModal: false,
            showDeleteProfilModal: false,
            showChangeGolfCourseModal: false,
            showChangeMembershipModal: false,
            showChangePasswordModal: false,
            loading: true,
            error: false,
            errorMessage: ""
        };
        this.setShowEditProfilModal = this.setShowEditProfilModal.bind(this);
        this.setShowDeleteProfilModal = this.setShowDeleteProfilModal.bind(this);
        this.setShowChangeGolfCourseModal = this.setShowChangeGolfCourseModal.bind(this);
        this.setShowChangeMembershipModal = this.setShowChangeMembershipModal.bind(this);
        this.setShowChangePasswordModal = this.setShowChangePasswordModal.bind(this);
    }

    componentDidMount = async () => {
        this.reload().then(() => {
                this.setState({ loading: false });
            }
        );
    }

    reload = async () => {
        let response = await getApi('/api/user/get');
        if (response.success) {
            this.setState({
                id: response.data.id,
                username: response.data.username,
                firstName: response.data.first_name,
                lastName: response.data.last_name,
                city: response.data.city,
                email: response.data.email,
                address: response.data.address,
                phone: response.data.phone,
                zip: response.data.zip,
                currentElem: response.data,
                membership: response.data.membership,
                roles: response.data.roles,
                currentAoi: response.data.aoi,
                availableAois: response.data.available_aois
            });
        }
        else {
            let message;
            try {
                message = ": " + await response.data.json();
            }
            catch (e) {
                message = "";
            }
            this.setState({error: true});
            this.setState({errorMessage: "Error " + response.data.status + message});
        }
    }
    
    setShowEditProfilModal (show = true) {
        this.setState({showEditProfilModal: show});
    }

    setShowDeleteProfilModal (show = true) {
        this.setState({showDeleteProfilModal: show});
    }

    setShowChangeGolfCourseModal (show = true) {
        this.setState({showChangeGolfCourseModal: show});
    }

    setShowChangeMembershipModal (show = true) {
        this.setState({showChangeMembershipModal: show});
    }

    setShowChangePasswordModal (show = true) {
        this.setState({showChangePasswordModal: show});
    }

    render () {

        return (
        <>
            <NavigationBar />
            {!this.state.error &&
            <section className="container mt-4">
                {this.state.loading && LoadingIcon()}
                {!this.state.loading &&
                    <>
                    <div className="profile-ändern-card">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="profile-ändern-img-container">
                                    <img src={golfImg} className="profile-ändern-img" alt="" />
                                    <div className="img-title-container pl-4" onClick={this.setShowEditProfilModal}>
                                        <p>Profile Ändern</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-8">
                                <table class="table">
                                    <tr>
                                        <td>Adresse</td>
                                        <td>{this.state.address}</td>
                                    </tr>
                                    <tr>
                                        <td>Stadt</td>
                                        <td>{this.state.city}</td>
                                    </tr>
                                    <tr>
                                        <td>PLZ</td>
                                        <td>{this.state.zip}</td>
                                    </tr>
                                    <tr>
                                        <td>Telefon</td>
                                        <td>{this.state.phone}</td>
                                    </tr>
                                    <tr>
                                        <td>Email</td>
                                        <td>{this.state.email}</td>
                                    </tr>
                                    <tr>
                                        <td>Current Golf Course</td>
                                        <td>{this.state.currentAoi}</td>
                                    </tr>
                                    <tr>
                                        <td>Roles</td>
                                        <td>{this.state.roles.join(', ')}</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="gold-member-container mt-3">
                        <p>{this.state.membership}</p>
                    </div>
                    <div className="row mt-3">
                        <div className="col-md-4 mb-4">
                            <div className="golfplatz-ändern-img-container">
                                <img src={golfBallImg} className="golfplatz-ändern-img" alt="" />
                                <div className="img-title-container pl-4" onClick={this.setShowChangeGolfCourseModal}>
                                    <p>Golfplatz Ändern</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mb-4">
                            <div className="mitgliedschaft-ändern-img-container">
                                <img src={loginImg} className="mitgliedschaft-ändern-img" alt="" />
                                <div className="img-title-container pl-4" onClick={this.setShowChangeMembershipModal}>
                                    <p>Mitgliedschaft Ändern</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mb-4">
                            <div className="mitgliedschaft-ändern-img-container">
                                <img src={loginImg} className="mitgliedschaft-ändern-img" alt="" />
                                <div className="img-title-container pl-4" onClick={this.setShowChangePasswordModal}>
                                    <p>Passwort ändern</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col">
                            <button class="btn btn-danger" onClick={this.setShowDeleteProfilModal}>Delete Profile</button>
                        </div>
                    </div>
                    </>
                }
            </section>
            }
            {   
                this.state.error &&
                <div className="form-group">
                    <p>{this.state.errorMessage}</p>
                </div>
            }
            {
                this.state.showEditProfilModal && <EditProfil reload={this.reload} source="profile" id={this.state.id} elem={this.state.currentElem} setShowModal={this.setShowEditProfilModal} showModal={this.state.showEditProfilModal} />
            }
            {
                this.state.showDeleteProfilModal && <DeleteProfil reload={this.reload} source="profile" type="User" id={this.state.id} name={this.state.username} setShowModal={this.setShowDeleteProfilModal} showModal={this.state.showDeleteProfilModal} />
            }
            {
                this.state.showChangeGolfCourseModal && <ChangeGolfCourse reload={this.reload} source="profile" currentCourse={this.state.currentAoi} availableCourses={this.state.availableAois} setShowModal={this.setShowChangeGolfCourseModal} showModal={this.state.showChangeGolfCourseModal} />
            }
            {
                this.state.showChangeMembershipModal && <ChangeMembership reload={this.reload} source="profile" id={this.state.id} currentMembership={this.state.membership} setShowModal={this.setShowChangeMembershipModal} showModal={this.state.showChangeMembershipModal} />
            }
            {
                this.state.showChangePasswordModal && <ChangePassword reload={this.reload} source="profile" id={this.state.id} setShowModal={this.setShowChangePasswordModal} showModal={this.state.showChangePasswordModal} />
            }

        </>
    )
        }
}