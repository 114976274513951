import "./LoadingIcon.scss";

export default function loadingIcon () {
    return (
        <>
            <div class="loader-container">
                <div class="loader"></div>
            </div>
        </>
    )
}