import React from 'react';
import './ChangeGolfCourse.scss';
import Modal from 'react-bootstrap/Modal';
import { Component } from 'react';
import { postApi } from "../../../../services/api/requestApi";

export default class ChangeGolfCourse extends Component {

    constructor (props) {
        super(props);
        this.state = {
            availableCourses: props.availableCourses,
            currentCourse: props.currentCourse,
            newCourse: props.newCourse,
            showModal: props.showModal,
            setShowModal: props.setShowModal,
            source: props.source,
            reload: props.reload
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleClose = () => {
        this.state.setShowModal(false);
    }

    handleChange (e) {
        this.setState({newCourse: e.target.value});
    }

    handleSubmit = async() => {
        let url = '/api/user/update';
        let body = {
            'aoi': this.state.newCourse
        }
        let response = await postApi(url, body);
        if (response !== false) {
            this.handleClose();
            this.state.reload();
        }
    }

    renderAois () {
        let options = [];
        this.state.availableCourses.map((aoi) => {
            options.push(<option value={aoi.name}>{aoi.name}</option>);
        })
        return options;
    }

    render () {
        return (
            <>
                <Modal animation={false} show={this.state.showModal} onHide={this.handleClose} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Golfplatz Ändern</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form className="pb-4 mb-4">
                            <div className="form-group pl-4 pr-4">
                                <select defaultValue={this.state.currentCourse} onChange={this.handleChange} className="form-control">
                                    {this.renderAois()}
                                </select>
                            </div>
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="d-flex">
                            <button className="btn btn-success mr-3" onClick={this.handleSubmit} type="button">Change</button>
                            <button className="btn btn-danger" onClick={this.handleClose} type="button">Abort</button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }
}