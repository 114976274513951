import React from 'react';
import { Link } from "react-router-dom";
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import './NavigationBar.scss';
import golf from '../../assets/images/golf.jpg';

export default function NavigationBar() {
    return (
        <>
            <Navbar collapseOnSelect expand="md">
                <Navbar.Brand href="/admin">Karuna</Navbar.Brand>
                <Navbar.Toggle aria-controls="navigation-bar" />
                <Navbar.Collapse id="navigation-bar">
                    <Nav className="ml-auto pt-1">
                        <Nav.Link as={Link} to="/admin/user-profil" className="nav-link">User</Nav.Link>
                        <Nav.Link as={Link} to="/admin/users" className="nav-link">Users</Nav.Link>
                        <Nav.Link as={Link} to="/admin/scripts" className="nav-link">Scripts</Nav.Link>
                        <Nav.Link as={Link} to="/admin/aois" className="nav-link">Golfplätze</Nav.Link>
                        <Nav.Link as={Link} to="/gee" className="nav-link">Userview</Nav.Link>
                        <div className="admin-img-container">
                            <img
                                src={golf}
                                className="admin-img"
                                alt=""
                            />
                        </div>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </>
    )
}