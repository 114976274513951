import { Component } from "react";
import { getApi } from "../services/api/requestApi";
import Pagination from "react-js-pagination";
import NavigationBar from '../components/navigation-bar/NavigationBar';

export default class Dashboard extends Component{

    constructor(props, state) {
        super(props);
        this.state = state;
    }

    paginate(pageNumber) {
        this.setState({currentPage: pageNumber});
    }
    nextPage() {
        this.setState((state, currentPage) => ({
            currentPage: this.state.currentPage + 1
        }));
    }
    previousPage() {
        this.setState((state, currentPage) => ({
            currentPage: this.state.currentPage - 1
        }));
    }
    handlePageChange(pageNumber) {
        this.setState({currentPage: pageNumber});
    }

    /**
     * Execute the get call and sets the state, if any errors occur
     * set the errorstate
     * 
     * @author Hagen Hoppenstedt
     * @param type {String} The type of resource we want to access
     * @param url {String} The url where the resource can be requested
     */
    load = async(type, url) => {
        this.setState({loading: true});
        let response = await getApi(url);
        this.setState({loading: false});
        if (response.success) {
            this.setState({totalItemsCount: response.data.length});
            this.setState({[type]: response.data});
        }
        else {
            let message;
            try {
                message = ": " + await response.data.json();
            }
            catch (e) {
                message = "";
            }
            this.setState({error: true});
            try {
                this.setState({errorMessage: "Error " + response.data.status + message});
            }
            catch (e) {
                this.setState({errorMessage: "There was a error getting the data!"});
            }
        }
    }

    setShowModal (type, show = true) {
        let key = 'show' + type + 'Modal';
        this.setState({[key]: show});
    }

    render (content) {
        return (
            <>
                <NavigationBar />
                {!this.state.error &&
                <section className="container mt-4">
                    {content}
                </section>
                } 
                {this.state.error &&
                 <div className="form-group">
                    <p>{this.state.errorMessage}</p>
                </div>}
            </>
        )
    }


}