import './App.scss';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import 'rsuite/dist/styles/rsuite-default.css';
import logo from './logo.svg';
import Login from './pages/login/Login';
import Register from './pages/register/Register';
import UserProfil from './pages/admin/user/user-profil/UserProfil';
import Scripts from './pages/admin/dashboard/ScriptDashboard';
import Users from './pages/admin/dashboard/UserDashboard';
import Aois from './pages/admin/dashboard/AoiDashboard';
import UserView from './pages/user/UserView';

// function App() {
//   return (   
//     <Router>
//        <Switch>
//          <Route path='/' exact component={Login}/>
//          <Route path='/login' exact  component={Login}/>
//          <Route path='/register' exact  component={Register}/>
//          <Route path='/gee' exact component={UserView} />
//          {/* <Route path='/admin/user-profil' exact  component={UserProfil}/> */}
//          <Route path='/admin' render={({ match: { url}})=> (
//            <> 
//               <Route path={`${url}/`} component={Users} exact />
//               <Route path={`${url}/user-profil`} component={UserProfil}/>
//               <Route path={`${url}/users`} component={Users}/>
//               <Route path={`${url}/scripts`} component={Scripts}/>
//               <Route path={`${url}/aois`} component={Aois}/>
//            </>
//          )}/>
//         </Switch>
//     </Router>
//   );
// }

function App() {
  return (   
    <Router>
       <Switch>
         <Route path='/' exact component={UserView}/>
        </Switch>
    </Router>
  );
}

export default App;