import React, { useState } from "react";
import './CreateUser.scss';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Component } from "react";
import { postApi } from "../../../../services/api/requestApi";

export default class CreateUser extends Component{

    constructor (props) {
        super (props);
        this.state = {
            username: "",
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            address: "",
            zip: "",
            city: "",
            newPassword: "",
            repeatNewPassword: "",
            membership: "Basic",
            role: ["ROLE_USER"],
            showModal: props.showModal,
            setShowModal: props.setShowModal,
            source: props.source,
            reload: props.reload
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleClose = () => {
        this.state.setShowModal('Create', false);
    }

    handleChange (e, type) {
        if (type === 'ROLE_USER' | type == 'ROLE_ADMIN') this.setState({role: [type]});
        else this.setState({[type]: e.target.value});
    }

    handleSubmit = async() => {
        if (this.state.newPassword === this.state.repeatNewPassword) {
            let url = "/oauth/createUser";
            let body = {
                'username': this.state.username,
                'email': this.state.email,
                'telephone': this.state.phone,
                'address': this.state.address,
                'firstName': this.state.firstName,
                'lastName': this.state.lastName,
                'zip': this.state.zip,
                'city': this.state.city,
                'password': this.state.newPassword,
                'membership': this.state.membership,
                'roles': this.state.role
            }
            let response = await postApi(url, body);
            if (response !== false) {
                this.handleClose();
                this.state.reload();
            }
        }
        else alert("The password doesn't match");
    }

    render(props) {
        return (
            <>
                <Modal animation={false} show={true} size="lg" onHide={this.handleClose} centered>
                    <Modal.Body>
                        <form className="p-4">
                            <div className="form-group">
                                <input type="text" className="form-control" placeholder="Username" value={this.state.username} onChange={(e) => this.handleChange (e, 'username')}/>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <input type="text" className="form-control" placeholder="Vorname" value={this.state.firstName} onChange={(e) => this.handleChange (e, 'firstName')}/>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <input type="text" className="form-control" placeholder="Nachname" value={this.state.lastName} onChange={(e) => this.handleChange (e, 'lastName')}/>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <input type="email" className="form-control" placeholder="E-Mail Adresse" value={this.state.email} onChange={(e) => this.handleChange (e, 'email')}/>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <input type="text" className="form-control" placeholder="Telefonnummer" value={this.state.phone} onChange={(e) => this.handleChange (e, 'phone')}/>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <input type="text" className="form-control"  placeholder="Adresse" value={this.state.address} onChange={(e) => this.handleChange (e, 'address')}/>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <input type="text" className="form-control"  placeholder="PLZ" value={this.state.zip} onChange={(e) => this.handleChange (e, 'zip')}/>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <input type="text" className="form-control"  placeholder="Stadt" value={this.state.city} onChange={(e) => this.handleChange (e, 'city')}/>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group">
                                <label>Passwort</label>
                                <input type="password" className="form-control" placeholder="Passwort" value={this.state.newPassword} onChange={(e) => this.handleChange (e, 'newPassword')}/>
                            </div>
                            <div className="form-group">
                                <label>Passwort wiederholen</label>
                                <input type="password" className="form-control" placeholder="Passwort wiederholen" value={this.state.repeatNewPassword} onChange={(e) => this.handleChange (e, 'repeatNewPassword')}/>
                            </div>

                            <div className="form-group">
                                <label>Choose Membership</label>
                                <select defaultValue={this.state.membership} onChange={(e) => this.handleChange (e, 'membership')} className="form-control">
                                    <option value="Basic">Basic</option>
                                    <option value="Premium">Premium</option>
                                </select>
                            </div>
                           
                            <div className="form-group">
                                <p>Setzen Sie die Berechtigungen:</p>
                            </div>
                            <div className="form-group">   
                                <div>
                                    <input type="radio" name="role" onChange={(e) => this.handleChange(e, 'ROLE_USER')}/>
                                    <label>User</label>
                                </div>
                                <div>
                                    <input type="radio" name="role" onChange={(e) => this.handleChange(e, 'ROLE_ADMIN')}/>
                                    <label>Admin</label>
                                </div>
                            </div>
                                
                            <div className="d-flex mt-4">
                                <button className="btn btn-primary mr-3" onClick={this.handleSubmit} type="button">Erstellen</button>
                                <button className="btn btn-primary" onClick={this.handleClose} type="button">Abbrechen</button>
                            </div>

                        </form>
                    </Modal.Body>
                </Modal>
            </>
        )
    }
}