import "./LoadingIconTwo.scss";

export default function loadingIconTwo () {
    return (
        <>
            <div class="loader-container-two">
                <div class="loader-two"></div>
            </div>
        </>
    )
}