import React from 'react';
import { Component } from 'react';
import { Link } from "react-router-dom";
import './Login.scss';

export default class Login extends Component {


    // doLogin = async(e) => { 
    //     e.preventDefault();
    //     console.log('login');  
    // }

    render() {
        return (
            <>
                <section className="d-flex justify-content-center align-items-center flex-column login-section">
                    <h2>Karuna Golf</h2>
                    <div>
                        <div className="card login-card">
                            <div className="card-header">
                                Login
                        </div>
                            <div className="card-body">
                                <form>
                                    <div className="form-group">
                                        <label>Email</label>
                                        <input type="email" className="form-control" placeholder="Default input" />
                                    </div>
                                    <div className="form-group">
                                        <label>Password</label>
                                        <input type="text" className="form-control" placeholder="Default input" />
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <button className="btn login-button mr-4"> <Link to="/admin" className="btn register-button" >Start</Link></button>
                                        <Link to="/register" className="btn register-button" >Register</Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}