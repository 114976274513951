import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { postApi } from "../../../services/api/requestApi";

export default class UploadScript extends React.Component {

    constructor (props) {
        super (props);
        this.state = {
            alias: '',
            selectedFile: null,
            showModal: props.showModal,
            setShowModal: props.setShowModal,
            reload: props.reload
        };
        this.handleFileChange = this.handleFileChange.bind(this);
        this.handleAliasChange = this.handleAliasChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleClose = () => {
        this.state.setShowModal('Upload', false);
    }

    handleFileChange (e) {
        this.setState({
            selectedFile: e.target.files[0],
            loaded: 0
        })
    }

    handleAliasChange (e) {
        this.setState({
            alias: e.target.value
        })
    }

    parseFile () {
        //TODO Extract the geometrics and parse the rest into a working script
        
    }

    handleSubmit = async() => {
        if (this.alias != "" && this.state.selectedFile != null) {
            let url = '/admin/scripts/create';
            let body = {
                alias: this.state.alias,
                name: this.state.selectedFile.name
            }
            let response = await postApi(url, body);
            if (response !== false) {
                this.handleClose();
                this.state.reload();
            }
        }
        else {
            alert("Bitte füllen Sie alle Felder aus!");
        }
    }

    render () {
        return (
            <>
                <Modal animation={false} show={this.state.showModal} onHide={this.handleClose} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Neues Skript hochladen</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-group">
                            <input type="text" className="form-control" placeholder="Alias" value={this.state.alias} onChange={this.handleAliasChange}/>
                        </div>
                        <div>
                            <input type="file" onChange={this.handleFileChange}/>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="d-flex">
                            <button className="btn btn-success mr-3" type="button"onClick={this.handleSubmit} >Confirm</button>
                            <button className="btn btn-danger" type="button" onClick={this.handleClose}>Abort</button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }
}