import React, { useState } from "react";
import { AiFillPropertySafety, AiOutlineCaretLeft, AiOutlineCaretRight } from 'react-icons/ai';
import { RiLayoutGridFill } from 'react-icons/ri';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import './SidePanel.scss';
import { DateRangePicker } from 'rsuite';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Draggable from 'react-draggable';
import {GEE_URL} from '../../../env'
import Chart from "../charts/Chart";
import Modal from 'react-bootstrap/Modal';

export default function SidePanel(props) {
    const [sidePanelOpened, setSidePanelOpened] = useState(true);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [error, setError] = useState("");

    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    let colors = ["#f0af07", "#0f8755", "#76b349"];

    const toggleSidePanel = () => {
        if (sidePanelOpened) {
            setSidePanelOpened(false)
        } else {
            setSidePanelOpened(true)
        }
    }

    /**
    * This function helps to send a post request to the express server 
    * to get the data from the gee scripts which is being filtered using the dates from the date picker
    * 
    * @author Philip Sada
    */
    const filterGEEScripts = () => {
        setError('');
        props.setIsLoading({ startView: false, startViewBtn: false, ndwiBtn: false, eviBtn: false, dateFilter: true });
        let button = '';
        switch (true) {
            case props.buttonActive.startView:
                button = 'view'
                break;
            case props.buttonActive.ndwi:
                button = 'ndwi'
                break;
            case props.buttonActive.evi:
                button = 'evi'
                break;
            default:
                break;
        }
        const data = { startDate: startDate, endDate: endDate }

        fetch(GEE_URL+"/gee-scripts/filter", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.text())
            .then((data) => {
                const formattedData = JSON.parse(data);
                const mapParams = {
                    center: { lng: formattedData.mapParams.lng, lat: formattedData.mapParams.lat },
                    zoom: formattedData.mapParams.zoom,
                }

                props.showEEImage(formattedData.mapLayers, button);
                // console.log(formattedData.mapLayers[0].mapid);
                props.setMapOptions(mapParams);
                props.setMapLayers(formattedData.mapLayers);
            }).catch(error => {
                if (error) {
                    setError('An error occurred. Please choose a different date or check your internet connection')
                    props.setIsLoading({ startView: false, startViewBtn: false, ndwiBtn: false, eviBtn: false, dateFilter: false });
                }
                console.log(error);
            })
    }
    /**
    * Displays the holes
    * 
    * @author Philip Sada
    */
    const showVisualization = (button) => {
        switch (button) {
            case 'view':
                props.setButtonActive({ startView: true, ndwi: false, evi: false })
                props.setIsLoading({ startView: false, startViewBtn: true, ndwiBtn: false, eviBtn: false });

                break;
            case 'ndwi':
                props.setButtonActive({ startView: false, ndwi: true, evi: false })
                props.setIsLoading({ startView: false, startViewBtn: false, ndwiBtn: true, eviBtn: false });
                break;
            case 'evi':
                props.setButtonActive({ startView: false, ndwi: false, evi: true })
                props.setIsLoading({ startView: false, startViewBtn: false, ndwiBtn: false, eviBtn: true });
                break;
            case 'Loch Auswahl':
                props.setHoleName(button)
                break;
            case 'Alle Löcher':
                props.setHoleName(button)
                break;
            case 'Loch 1':
                props.setHoleName(button)
                break;
            case 'Loch 2':
                props.setHoleName(button)
                break;
            case 'Loch 3':
                props.setHoleName(button)
                break;
            case 'Loch 4':
                props.setHoleName(button)
                break;
            default:
                break;
        }
        props.showEEImage(props.mapLayers, button);
    }
    /**
    * Formats the date from the date picker
    * 
    * @author Philip Sada
    */
    const formatDate = (date) => {
        let d = date,
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }
    /**
    * Adds the date to the react state and triggers the function
    * responsible for sending the post request to the express server
    * 
    * @author Philip Sada
    */
    const handleDates = (value) => {
        setStartDate(formatDate(value[0]));
        setEndDate(formatDate(value[1]));

        filterGEEScripts();
    }

    /**
    * Formats data in a format acceptable by chart.js
    * 
    * 
    * @author Philip Sada
    * @param data {array}
    * 
    */
    const formatToLineData = (data) => {
        let lineData = [];

        data.map(el => {
            lineData.push({ label: el.label, data: el.values, fill: false, borderColor: colors })
        })

        return lineData
    }

    return (
        <>
            <div className={sidePanelOpened ? "side-panel-container active" : "side-panel-container"}>
                <div className="mt-4 ml-2">
                    <p className="font-weight-bold">Choose a start and end date</p>
                </div>
                <div className="mt-4 mb-4 ml-2 mr-2">
                    {
                        props.isLoading.dateFilter &&
                        <div className="alert alert-success" role="alert">
                            <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true" />
                            <span className="">Filtering in progress...</span>
                        </div>
                    }
                    {
                        error !== '' &&
                        <div className="alert alert-danger" role="alert">
                            <span className="">{error}</span>
                        </div>
                    }

                    <DateRangePicker

                        className="date-range-picker"
                        onOk={(value) => handleDates(value)}
                    />
                </div>
                <div className="mt-4 mb-4 ml-2 mr-2">
                    <DropdownButton
                        id="dropdown-item-button"
                        title={props.holeName}
                        // className="mt-2"
                        variant="success"
                        size="sm"
                    >
                        <Dropdown.Item as="button" className="dropdown-item" onClick={() => showVisualization('Loch 1')}>Loch 1</Dropdown.Item>
                        <Dropdown.Item as="button" className="dropdown-item" onClick={() => showVisualization('Loch 2')}>Loch 2</Dropdown.Item>
                        <Dropdown.Item as="button" className="dropdown-item" onClick={() => showVisualization('Loch 3')}>Loch 3</Dropdown.Item>
                        <Dropdown.Item as="button" className="dropdown-item" onClick={() => showVisualization('Loch 4')}>Loch 4</Dropdown.Item>
                        <Dropdown.Item as="button" className="dropdown-item" onClick={() => showVisualization('Alle Löcher')}>Alle Löcher</Dropdown.Item>
                    </DropdownButton>
                </div>

                <button className="toggle-side-panel-btn" onClick={toggleSidePanel}>
                    {
                        sidePanelOpened ? <AiOutlineCaretLeft className="caret-icon" /> : <AiOutlineCaretRight className="caret-icon" />
                    }
                </button>
                <div className="visualization-container">

                    <div className="card visualisierung-card p-2">
                        <h4 className="pb-2">Visualisierung</h4>
                        <p>Bitte wählen sie eine Visualisierung für die karte aus</p>
                        <div className="d-flex pt-3 justify-content-center align-items-center">
                            {
                                props.isLoading.startViewBtn ?
                                    <button className="btn" disabled>
                                        <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true" />
                                    </button>
                                    :
                                    <button className="btn" onClick={() => showVisualization("view")}>Start View</button>
                            }
                            {
                                props.isLoading.ndwiBtn ?
                                    <button className="btn" disabled>
                                        <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true" />
                                    </button>
                                    :
                                    <button className="btn" onClick={() => showVisualization("ndwi")}>NDWI</button>
                            }

                            {
                                props.isLoading.eviBtn ?
                                    <button className="btn" disabled>
                                        <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true" />
                                    </button>
                                    :
                                    <button className="btn" onClick={() => showVisualization("evi")}>EVI</button>
                            }


                        </div>
                    </div>
                    {
                        props.chartData.length > 0 &&
                        <div className='d-flex justify-content-end mt-3 mb-3 mr-2'>
                            <RiLayoutGridFill onClick={handleShow} className='grid-view-icon' />
                        </div>
                    }


                    <div>
                        {
                            props.chartData.map((data, index) =>
                                <div key={index}>
                                    <Accordion defaultActiveKey="0">
                                        <Card>
                                            <Accordion.Toggle as={Card.Header} eventKey="0" className="side-panel-card-header">
                                                {data.band}
                                            </Accordion.Toggle>
                                            <Accordion.Collapse eventKey="0">
                                                <Card.Body className="card-body">

                                                    <Chart
                                                        xAxis={props.chartDates}
                                                        yAxis={formatToLineData(data.values)}
                                                    />
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>
                                </div>

                            )
                        }
                    </div>



                </div>
            </div>
            {
                show &&
                <Modal animation={false} show={show} size="xl" onHide={handleClose} centered>
                    <Modal.Body className='grid-view-modal-body'>
                        <div className='row'>
                            {
                                props.chartData.map((data, index) =>
                                    <div className='col-md-6' key={index}>
                                        <p className='pt-2 font-weight-bold'> {data.band}</p>
                                        <Chart
                                            xAxis={props.chartDates}
                                            yAxis={formatToLineData(data.values)}
                                        />
                                    </div>
                                )
                            }
                        </div>

                    </Modal.Body>

                </Modal>
            }
        </>
    )
}