import React, { useState } from "react";
import './EditProfil.scss';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Component } from "react";
import { postApi } from "../../../../services/api/requestApi";

export default class EditProfil extends Component{

    constructor (props) {
        super (props);
        this.state = {
            id: props.id,
            username: props.elem.username,
            email: props.elem.email,
            phone: props.elem.phone,
            address: props.elem.address,
            firstName: props.elem.first_name,
            lastName: props.elem.last_name,
            zip: props.elem.zip,
            city: props.elem.city,
            currentRole: props.elem.roles,
            newRole: props.elem.roles,
            showModal: props.showModal,
            setShowModal: props.setShowModal,
            source: props.source,
            reload: props.reload
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleClose = () => {
        this.state.setShowModal('EditProfil', false);
    }

    handleChange (e, type) {
        if (type === 'ROLE_USER' | type == 'ROLE_ADMIN') this.setState({newRole: [type]});
        else this.setState({[type]: e.target.value});
    }

    handleSubmit = async() => {
        let url;
        if (this.state.source === 'dashboard') {
            url = '/admin/user/update';
        }
        else {
            url = '/api/user/update';
        }
        let body = {
            'id': this.state.id,
            'username': this.state.username,
            'email': this.state.email,
            'telephone': this.state.phone,
            'address': this.state.address,
            'firstName': this.state.firstName,
            'lastName': this.state.lastName,
            'zip': this.state.zip,
            'city': this.state.city,
            'roles': this.state.newRole
        }
        let response = await postApi(url, body);
        if (response !== false) {
            this.handleClose();
            this.state.reload();
        }
    }

    render(props) {
        return (
            <>
                <Modal animation={false} show={true} size="lg" onHide={this.handleClose} centered>
                    <Modal.Body>
                        <form className="p-4">
                            <div className="form-group">
                                <input type="text" className="form-control" value={this.state.username}/>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <input type="email" className="form-control" value={this.state.firstName} onChange={(e) => this.handleChange (e, 'firstName')}/>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <input type="text" className="form-control" value={this.state.lastName} onChange={(e) => this.handleChange (e, 'lastName')}/>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <input type="email" className="form-control" value={this.state.email} onChange={(e) => this.handleChange (e, 'email')}/>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <input type="text" className="form-control" value={this.state.phone} onChange={(e) => this.handleChange (e, 'phone')}/>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <input type="text" className="form-control"  value={this.state.address} onChange={(e) => this.handleChange (e, 'address')}/>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <input type="text" className="form-control"  value={this.state.zip} onChange={(e) => this.handleChange (e, 'zip')}/>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <input type="text" className="form-control"  value={this.state.city} onChange={(e) => this.handleChange (e, 'city')}/>
                                    </div>
                                </div>
                            </div>
                            {this.state.source=='dashboard' &&
                            <div className="row align-items-center">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <p>The current role is <b>{this.state.currentRole}</b></p>
                                    </div>
                                    <div className="form-group">   
                                        <div>
                                            <input type="radio" name="role" onChange={(e) => this.handleChange(e, 'ROLE_USER')}/>
                                            <label>User</label>
                                        </div>
                                        <div>
                                            <input type="radio" name="role" onChange={(e) => this.handleChange(e, 'ROLE_ADMIN')}/>
                                            <label>Admin</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            }
                            <div className="d-flex mt-4">
                                <button className="btn btn-primary mr-3" onClick={this.handleSubmit} type="button">Ändern</button>
                                <button className="btn btn-primary" onClick={this.handleClose} type="button">Abbrechen</button>
                            </div>

                        </form>
                    </Modal.Body>
                </Modal>
            </>
        )
    }
}