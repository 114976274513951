import React from 'react';
import { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import { postApi } from "../../../services/api/requestApi";

export default class ChangeMembership extends Component{

    constructor (props) {
        super (props);
        this.state = {
            id: props.id,
            availableAois: props.availableAois,
            currentAois: props.currentAois,
            newAois: {},
            showModal: props.showModal,
            setShowModal: props.setShowModal,
            currentAssignees: props.currentAssignees,
            newAssignees: {},
            reload: props.reload
        };
        this.handleChange = this.handleChangeAoi.bind(this);
        this.handleChange = this.handleChangeMembership.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount = async () => {
        this.state.currentAssignees.forEach(assignee => {
            this.setState(prevState => ({
                newAssignees: {
                    ...prevState.newAssignees,
                    [assignee]: true
                }})
            )
        });
        this.state.currentAois.forEach(aoi => {
            this.setState(prevState => ({
                newAois: {
                    ...prevState.newAois,
                    [aoi]: true
                }})
            )
        });
    }

    handleClose = () => {
        this.state.setShowModal('Assign', false);
    }

    handleChangeAoi (e, type) {
        this.setState(prevState => ({
            newAois: {
                ...prevState.newAois,
                [type]: e.target.checked
            }})
        )
    }

    handleChangeMembership (e, type) {
        this.setState(prevState => ({
            newAssignees: {
                ...prevState.newAssignees,
                [type]: e.target.checked
            }})
        )
    }

    handleSubmit = async() => {
        let url = '/admin/scripts/update';

        console.log(this.state);

        //Push the set memberships into the array
        let memberships = [];
        for (let membership in this.state.newAssignees) {
            if (this.state.newAssignees[membership]) 
                memberships.push(membership);
        }

        let aois = [];
        for (let aoi in this.state.newAois) {
            if (this.state.newAois[aoi]) 
                aois.push(aoi);
        }

        let body = {
            'id': this.state.id,
            'membership': memberships,
            'aois': aois
        }
        let response = await postApi(url, body);
        if (response !== false) {
            this.handleClose();
            this.state.reload();
        }
    }

    renderAois () {
        let options = [];
        this.state.availableAois.map((aoi) => {
            options.push(
                <>
                    <div>
                        <input type="checkbox" onChange={(e) => this.handleChangeAoi(e, aoi.name)} checked={this.state.newAois[aoi.name]} />
                        <label>{aoi.name}</label>
                    </div>
                </>
            );
        })
        return options;
    }

    render () {
        return (
            <>
                <Modal animation={false} show={this.state.showModal} onHide={this.handleClose} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Assign allowed memberships and golf courses for script</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-md-4">
                                <p>Memberships</p>
                                <div>
                                    <input type="checkbox" onChange={(e) => this.handleChangeMembership(e, 'Basic')} checked={this.state.newAssignees.Basic}/>
                                    <label>Basic</label>
                                </div>
                                <div>
                                    <input type="checkbox" onChange={(e) => this.handleChangeMembership(e, 'Premium')} checked={this.state.newAssignees.Premium}/>
                                    <label>Premium</label>
                                </div>
                            </div>
                            <div className="col-md-8">
                                <p>Golf courses</p>
                                {this.renderAois()}
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="d-flex">
                            <button className="btn btn-success mr-3" type="button"onClick={this.handleSubmit} >Confirm</button>
                            <button className="btn btn-danger" type="button" onClick={this.handleClose}>Abort</button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }
}