import React from 'react';
import { Link } from "react-router-dom";
import './Register.scss';
import Modal from 'react-bootstrap/Modal';
import { postApi } from "../../services/api/requestApi";

export default class Register extends React.Component {

    constructor (props) {

        super (props);
        this.state =  {
            username: "",
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            address: "",
            zip: "",
            city: "",
            newPassword: "",
            repeatNewPassword: "",
            membership: "Basic",
            role: ["ROLE_USER"],
            loading: true,
            success: false,
            error: false,
            errorMessage: ""
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange (e, type) {
        if (type === 'ROLE_USER' || type === 'ROLE_ADMIN') this.setState({role: [type]});
        else this.setState({[type]: e.target.value});
    }

    handleSubmit = async() => {
        if (this.state.newPassword === this.state.repeatNewPassword) {
            let url = "/oauth/createUser";
            let body = {
                'username': this.state.username,
                'firstName': this.state.firstName,
                'lastName': this.state.lastName,
                'email': this.state.email,
                'telephone': this.state.phone,
                'address': this.state.address,
                'zip': this.state.zip,
                'city': this.state.city,
                'password': this.state.newPassword,
                'membership': this.state.membership,
                'roles': this.state.role
            }
            try {
                let response = await postApi(url, body);
                if (response.success) {
                    this.setState({success: true});
                }
                else this.setState({error: true});
                
            }
            catch (e) {
                this.setState({error: true});
            }
        }
        else alert("The password doesn't match");
    }

    handleClose = () => {
        this.setState({error: false});
    }

    render() {
        return (
            <>
                <section className="d-flex justify-content-center align-items-center flex-column register-section pl-2 pr-2">
                    <h2>Karuna Golf</h2>
                    <div>
                        <div className="card register-card mb-4">
                            <div className="card-header">
                                Register
                        </div>
                            <div className="card-body">
                                
                                    <div className="form-group">
                                        <input type="text" className="form-control" value={this.state.username} placeholder="Benutzername" onChange={(e) => this.handleChange (e, 'username')}/>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Vorname</label>
                                                <input type="text" className="form-control" value={this.state.firstName} onChange={(e) => this.handleChange (e, 'firstName')}/>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Nachname</label>
                                                <input type="text" className="form-control" value={this.state.lastName} onChange={(e) => this.handleChange (e, 'lastName')}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label>E-Mail Adresse</label>
                                        <input type="email" className="form-control" value={this.state.email} onChange={(e) => this.handleChange (e, 'email')}/>
                                    </div>
                                    <div className="form-group">
                                        <label>Adresse</label>
                                        <input type="type" className="form-control" value={this.state.address} onChange={(e) => this.handleChange (e, 'address')}/>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Stadt</label>
                                                <input type="text" className="form-control" value={this.state.city} onChange={(e) => this.handleChange (e, 'city')}/>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>PLZ</label>
                                                <input type="text" className="form-control" value={this.state.zip} onChange={(e) => this.handleChange (e, 'zip')}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label>Telefonnummer</label>
                                        <input type="tel" className="form-control" value={this.state.phone} onChange={(e) => this.handleChange (e, 'phone')}/>
                                    </div>
                                    <div className="form-group">
                                        <label>Passwort</label>
                                        <input type="password" className="form-control" value={this.state.newPassword} onChange={(e) => this.handleChange (e, 'newPassword')}/>
                                    </div>
                                    <div className="form-group">
                                        <label>Passwort wiederholen</label>
                                        <input type="password" className="form-control" value={this.state.repeatNewPassword} onChange={(e) => this.handleChange (e, 'repeatNewPassword')}/>
                                    </div>
                                    <div className="form-group">
                                        <label>Choose a Membership</label>
                                        <select className="form-control" onChange={(e) => this.handleChange (e, 'membership')}>
                                            <option>Basic</option>
                                            <option>Premium</option>
                                        </select>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <button className="btn register-button mr-4" onClick={this.handleSubmit.bind(this)}>Register</button>
                                        <Link to="/login" className="btn login-button" >Login</Link>
                                    </div>
                            </div>
                        </div>
                    </div>
                    <Modal animation={false} show={this.state.success} size="lg" centered>
                        <Modal.Header>
                            Account wurde erfolgreich erstellt!
                        </Modal.Header>
                        <Modal.Body>
                            Ihr Account wurde erstellt und muss von einem Administrator freigegeben werden.
                        </Modal.Body>
                        <Modal.Footer>
                            <Link to="/login" className="btn login-button" >Zum Login</Link>
                        </Modal.Footer>
                    </Modal>
                    <Modal animation={false} show={this.state.error} size="lg" onHide={this.handleClose} centered>
                        <Modal.Header>
                            Es ist ein Fehler aufgetreten, bitte versuchen Sie es erneut.
                        </Modal.Header>
                        <Modal.Body>
                            Bei der Erstellung Ihres Accounts ist ein Fehler aufgetreten, bitte versuchen Sie es erneut.
                        </Modal.Body>
                        <Modal.Footer>
                            <button className="btn btn-primary" onClick={this.handleClose} type="button">OK</button>
                        </Modal.Footer>
                    </Modal>
                </section>
            </>
        )
    }
}