import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { postApi } from "../services/api/requestApi";

export default function deleteEntry (props) {

    const handleClose = () => {
        props.setShowModal('Delete', false);
    }

    const handleSubmit = async() => {
        let url;
        switch (props.type) {
            case ("User"): 
                if (props.source === "dashboard") {
                    url = '/admin/user/delete';
                    break;
                }
                else {
                    url = '/api/user/delete';
                    break;
                }
            case ("Script"):
                url = '/admin/scripts/delete'
                break;
            case ("Golfplatz"):
                url = '/admin/aoi/delete'
                break;
        }
        let body = {
            'id': props.id
        }
        let response = await postApi(url, body);
        if (response !== false) {
            handleClose();
            props.reload();
        }
    }

    return (
        <>
            <Modal animation={false} show={props.showModal} size="lg" onHide={handleClose} centered>
                <Modal.Dialog>
                    <Modal.Header closeButton>
                        <Modal.Title>Deleting {props.type}</Modal.Title>
                    </Modal.Header>
                
                    <Modal.Body>
                        <p>Are you sure you want to delete {props.name}?</p>
                    </Modal.Body>
                
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>Abort</Button>
                        <Button variant="primary" onClick={handleSubmit}>Delete it</Button>
                    </Modal.Footer>
                </Modal.Dialog>
            </Modal>
        </>
    );
}