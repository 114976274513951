import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import Modal from 'react-bootstrap/Modal';
import './chart.scss';
import moment from 'moment';

export default function Chart(props) {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  
  const data = {
    labels: props.xAxis,
    datasets: props.yAxis

  };


  return (
    <>
      <div onClick={handleShow} className='chart-container'>
        <Line
          data={data}
          options={{
            scales: {
                x: {
                    ticks: {
                        autoSkip: false,
                        maxRotation: 0,
                        minRotation: 0,
                        align:'start',
                        callback: function(value, index) {
                           // Hide the label of every 8th dataset
                           return index % 8 === 0 ? moment(this.getLabelForValue(value)).format('MMM, YYYY'): '';
                        }
                    }
                },
               
            }
        }}

        />
      </div>
      {
        show &&  
              <Modal animation={false} show={show} size="lg" onHide={handleClose} centered>
                  <Modal.Body>
                      <Line
                          data={data}
                          options={{
                              scales: {
                                  x: {
                                      ticks: {
                                          autoSkip: false,
                                          maxRotation: 0,
                                          minRotation: 0,
                                          align: 'start',
                                          callback: function (value, index) {
                                              // Hide the label of every 8th dataset

                                              return index % 8 === 0 ? moment(this.getLabelForValue(value)).format('MMM, YYYY') : '';
                                          }
                                      }
                                  },

                              }
                          }}
                      />
                  </Modal.Body>
              </Modal>
      }

    </>
  )
}